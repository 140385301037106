import {memo} from "react";
import styled from "styled-components";
import {theme} from "../../config/theme";

const Divider = memo(() => {
  return (
    <DividerWrapper>
      <DividerStyled />
    </DividerWrapper>
  );
});

const DividerWrapper = styled.div`
  position: relative;
`;

const DividerStyled = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(225deg, #010314, ${theme.colors.gold} 54.98%, #010314);
  position: absolute;
  left: 0;
  bottom: 0;
`;

export default Divider;
