import {FC, memo, useState} from "react";
import styled from "styled-components";
import {defaultTransition} from "../../config/consts";
import {theme} from "../../config/theme";
import {ILinkProps} from "./types";

const Link: FC<ILinkProps> = memo((props) => {
  const {children, href} = props;

  const [isLinkActive, setIsLinkActive] = useState(false);

  return (
    <LinkWrapper
      href={href}
      onMouseEnter={() => setIsLinkActive(true)}
      onMouseLeave={() => setIsLinkActive(false)}
    >
      <LinkUnderline isLinkActive={isLinkActive} />
      <LinkText isLinkActive={isLinkActive}>{children}</LinkText>
    </LinkWrapper>
  );
});

const LinkWrapper = styled.a`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: max-content;
  padding: 0 5px 3px 5px;
  text-decoration: none;
  width: max-content;
`;

const LinkUnderline = styled.div<{isLinkActive: boolean}>`
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 3px;
  transition: transform ${defaultTransition};
  background: ${theme.colors.gold};
  transform: ${({isLinkActive}) =>
    isLinkActive ? "translate(-6px, 0)" : "translate(calc(-100% - 6px), 0)"};
`;

const LinkText = styled.div<{isLinkActive: boolean}>`
  transition: color ${defaultTransition};
  color: ${({isLinkActive}) =>
    isLinkActive ? theme.colors.white : theme.colors.translucent.white(0.8)};
`;

export default Link;
