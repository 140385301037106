import {createGlobalStyle} from "styled-components";
import commonClasses from "./common";
import scrollbarClasses from "./scrollbar";
import toastClasses from "./toast";
import particles3dClasses from "./particles3d";
import catalogBlockClasses from "./catalogBlock";
import animatedTextClasses from "./animatedText";

const GlobalStyle = createGlobalStyle`
  ${commonClasses}
  ${toastClasses}
  ${scrollbarClasses}
  ${particles3dClasses}
  ${catalogBlockClasses}
  ${animatedTextClasses}
`;

export default GlobalStyle;
