import {memo, useState} from "react";
import styled, {css} from "styled-components";
import Icon from "../../../components/Icon";
import PretorianLogoIcon from "../../../components/Icons/PretorianLogoIcon";
import {defaultTransition, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";

const PoweredByBlock = memo(() => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <PoweredByWrapper
      href={"https://pretorianecosystem.nl/"}
      target={"_blank"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TextWrapper isHovered={isHovered}>Powered by Pretorian</TextWrapper>
      <IconWrapper isHovered={isHovered}>
        <Icon
          icon={PretorianLogoIcon}
          iconTheme={{
            color: isHovered ? theme.colors.gold : theme.colors.translucent.white(0.8),
            hoverColor: theme.colors.gold,
          }}
        />
      </IconWrapper>
    </PoweredByWrapper>
  );
});

const PoweredByWrapper = styled.a`
  padding: 10px 100px;
  align-items: center;
  text-decoration: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  user-select: none;

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 10px 15px;
  }
`;

const TextWrapper = styled.div<{isHovered: boolean}>`
  transition: color ${defaultTransition};
  color: ${({isHovered}) => (isHovered ? theme.colors.gold : theme.colors.translucent.white(0.8))};
`;

const IconWrapper = styled.div<{isHovered: boolean}>`
  transition: transform ${defaultTransition};
  ${({isHovered}) =>
    isHovered &&
    css`
      transform: rotate(25deg) scale(1.2);
    `}
`;

export default PoweredByBlock;
