import {memo, useEffect} from "react";
import styled from "styled-components";
import {BlurScrollEffect as BlurScrollEffect4} from "../../components/AnimatedText/blurScrollEffect";
import Divider from "../../components/Divider";
import CatalogBlock from "./CatalogBlock";
import FooterBlock from "./FooterBlock";
import HeaderBlock from "./HeaderBlock";
import InfoBlock from "./InfoBlock";
import RoadmapBlock from "./RoadmapBlock";
import PoweredByBlock from "./PoweredByBlock";
import PromoBlock from "./PromoBlock";
import TokenomicsBlock from "./TokenomicsBlock";

window.gsap.registerPlugin(ScrollTrigger);
const init = () => {
  const effects = [{selector: "[data-effect-4]", effect: BlurScrollEffect4}];
  effects.forEach(({selector, effect}) => {
    document.querySelectorAll(selector).forEach((el) => {
      new effect(el);
    });
  });
};

const MainPage = memo(() => {
  useEffect(() => {
    init();
  }, []);

  return (
    <MainPageWrapper>
      <HeaderBlocksWrapper>
        <PoweredByBlock />
        <HeaderBlock />
      </HeaderBlocksWrapper>
      <PromoBlock />
      <Divider />
      <RoadmapBlock />
      <Divider />
      <TokenomicsBlock />
      <Divider />
      <CatalogBlock />
      <Divider />
      <InfoBlock />
      <Divider />
      <FooterBlock />
    </MainPageWrapper>
  );
});

const MainPageWrapper = styled.div``;

const HeaderBlocksWrapper = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
`;

export default MainPage;
