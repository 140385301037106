const totalSupply = {
  leftText: "Total Supply",
  rightText: "1.000.000",
};
const ticker = {
  leftText: "Ticker",
  rightText: "$HASH",
};
const buyTax = {
  leftText: "Buy Tax",
  rightText: "5%",
};
const sellTax = {
  leftText: "Sell Tax",
  rightText: "5%",
};
const liquidityPool = {
  leftText: "Liquidity Pool",
  rightText: "60%",
};
const staking = {
  leftText: "Staking",
  rightText: "10%",
};
const mining = {
  leftText: "Mining",
  rightText: "10%",
};
const team = {
  leftText: "Team",
  rightText: "10%",
};
const marketing = {
  leftText: "Marketing",
  rightText: "10%",
};

export const tokenomicsBlockPlatesDesktop = [
  [totalSupply, ticker],
  [buyTax, sellTax],
  [liquidityPool, staking, mining, team, marketing],
];

export const tokenomicsBlockPlatesMobile = [
  [totalSupply],
  [ticker],
  [buyTax],
  [sellTax],
  [liquidityPool, staking],
  [mining, team],
  [marketing],
];
