import {theme} from "../../config/theme";
import EmergeMaterial from "./EmergeMaterial";
import {useState, useEffect, useRef} from "react";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {useLayoutEffect} from "react";
import * as THREE from "three";
import useScreenSize from "./useScreenSize";
import {View} from "@react-three/drei";

const PIXELS = [
  1, 1.5, 2, 2.5, 3, 1, 1.5, 2, 2.5, 3, 3.5, 4, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 3, 3.5, 4, 4.5,
  5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 20, 100,
].map((v) => v / 100);

export default function EmergingImage({...props}) {
  const fillColor = theme.colors.gold;

  const [refMesh, setRefMesh] = useState(null);
  const [texture, setTexture] = useState(null);
  const [textureSize, setTextureSize] = useState([0, 0]);
  const [elementSize, setElementSize] = useState([0, 0]);
  const ref = useRef();
  const screenSize = useScreenSize();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    new THREE.TextureLoader().loadAsync(props.url).then((data) => {
      // data.colorSpace = THREE.LinearSRGBColorSpace;
      setTextureSize([data.source.data.width, data.source.data.height]);
      setTexture(data);
    });
  }, [props.url]);

  useEffect(() => {
    if (refMesh) {
      refMesh.material.uProgress = 0;
      refMesh.material.uType = props.type;
    }
  }, [props.type, refMesh]);

  useGSAP(() => {
    if (refMesh?.material) {
      gsap.to(refMesh.material, {
        uProgress: isIntersecting ? 1 : 0,
        duration: 1.5,
        ease: "none",
      });
    }
  }, [isIntersecting, props.type]);

  // scroll check
  // only set intersecting if refMesh is available, important
  // Thanks Cody Bennett for this issue!
  useLayoutEffect(() => {
    if (refMesh) {
      const bounds = ref.current.getBoundingClientRect();
      setElementSize([bounds.width, bounds.height]);
      refMesh?.scale.set(bounds.width, bounds.height, 1);
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });
      observer.observe(ref.current);
    }
  }, [refMesh]);

  // resize
  useEffect(() => {
    const bounds = ref.current.getBoundingClientRect();
    setElementSize([bounds.width, bounds.height]);
    refMesh?.scale.set(bounds.width, bounds.height, 1);
  }, [refMesh?.scale, screenSize]);

  return (
    <View {...props} ref={ref}>
      <mesh ref={setRefMesh}>
        {/* eslint-disable react/no-unknown-property */}
        <emergeMaterial
          uFillColor={new THREE.Color(fillColor)}
          transparent={true}
          uTexture={texture}
          uPixels={PIXELS}
          uTextureSize={new THREE.Vector2(textureSize[0], textureSize[1])}
          uElementSize={new THREE.Vector2(elementSize[0], elementSize[1])}
        />
        <planeGeometry args={[1, 1]} />
      </mesh>
    </View>
  );
}
