import {memo} from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";

const PromoBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  return (
    <PromoBlockWrapper>
      <VideoWrapper>
        <Video src="/images/bg-video.mp4" autoPlay muted loop controls={false} playsInline />
      </VideoWrapper>
      <TextWrapper>
        <Text isMobile={isMobile}>
          <span>HashHub</span>
          <span>Technologies</span>
        </Text>
      </TextWrapper>
    </PromoBlockWrapper>
  );
});

const PromoBlockWrapper = styled.div`
  padding: 0 100px;
  height: 100vh;
  position: relative;
  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 0 15px;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  z-index: 0;
  grid-template-areas: "background";
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  pointer-events: none;
  place-items: center;
`;

const Video = styled.video`
  position: relative;
  z-index: -2;
  grid-area: background;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  //padding: 1.5rem;
  position: relative;
  min-height: 100vh;
  display: grid;
  z-index: 0;
  width: 100%;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  justify-items: start;
  grid-template-columns: auto auto auto;
  grid-template-areas:
    \t\t"site year menu"
    \t"tagline tagline tagline"
    \t\t"contact contact contact"
    \t\t"works works works"
    \t\t"content content content"
    \t\t"title title title"
    \t\t"links links links";

  @media (min-width: ${mobileMaxWidthPx}) {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    grid-template-columns: 20% 15% 30% 1fr 1fr;
    grid-template-rows: auto auto 1fr 1fr auto;
    align-content: space-between;
    grid-template-areas:
      \t\t"tagline ... site year menu"
      \t\t"tagline content content content content"
      \t\t"contact content content content content"
      \t\t"works content content content content"
      \t\t"title title links links links";
  }

  a {
    pointer-events: auto;
  }
`;

const Text = styled.div<{isMobile: boolean}>`
  text-transform: none;
  font-weight: 400;
  margin: 0;
  grid-area: content;
  font-size: 8vw;
  display: grid;
  align-content: center;
  width: 100%;
  pointer-events: none;
  user-select: none;
  color: ${theme.colors.white};
  line-height: ${({isMobile}) => (isMobile ? 1.4 : 1.1)};

  @media (min-width: ${mobileMaxWidthPx}) {
    grid-area: 5 / 3 / 2 / 6;
  }

  @media (max-width: ${mobileMaxWidthPx}) {
    margin-top: 120%;
    font-size: 12vw;
  }

  span {
    :last-child {
      margin-left: 1em;
    }
  }
`;

export default PromoBlock;
