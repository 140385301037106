import {css} from "styled-components";
import {mobileMaxWidthPx} from "../config/consts";

export default css`
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  //svg:not(:root) {
  //  overflow: hidden;
  //}

  figure {
    margin: 0;
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
  }

  #roadmap-block,
  #footer-block {
    /* Color schemes */

    .demo-1 {
      --color-text: #fff;
      --color-bg: #fff;
      --color-link: #236554;
      --color-link-hover: #fff;
      --color-top-right: limegreen;
      --color-bottom-left: cyan;
    }

    .completed .demo-1 {
      --color-text: #222;
      --color-link: #222;
      --color-link-hover: #aaa;
    }

    .demo-2 {
      --color-text: #fff;
      --color-bg: #fff;
      --color-link: #56311e;
      --color-link-hover: #fff;
      --color-top-right: red;
      --color-bottom-left: orange;
    }

    .completed .demo-2 {
      --color-text: #222;
      --color-link: #222;
      --color-link-hover: #aaa;
    }

    .demo-3 {
      --color-text: #fff;
      --color-bg: #fff;
      --color-link: #0d0084;
      --color-link-hover: #fff;
      --color-top-right: blue;
      --color-bottom-left: magenta;
    }

    .completed .demo-3 {
      --color-text: #222;
      --color-link: #222;
      --color-link-hover: #aaa;
    }

    .demo-4 {
      --color-text: #fff;
      --color-bg: #fff;
      --color-link: #711d55;
      --color-link-hover: #fff;
      --color-top-right: magenta;
      --color-bottom-left: red;
    }

    .completed .demo-4 {
      --color-text: #222;
      --color-link: #222;
      --color-link-hover: #aaa;
    }

    .demo-5 {
      --color-text: #fff;
      --color-bg: #003877;
      --color-link: #00bfff;
      --color-link-hover: #fff;
      --color-top-right: #006f79;
      --color-bottom-left: darkblue;
    }

    .completed .demo-5 {
      --color-text: #e2dcdc;
      --color-link: #01172f;
      --color-link-hover: #aaa;
    }

    .demo-6 {
      --color-text: #fff;
      --color-bg: #fff;
      --color-link: #432e63;
      --color-link-hover: #fff;
      --color-top-right: #ff005e;
      --color-bottom-left: #00d0ff;
    }

    .completed .demo-6 {
      --color-text: #222;
      --color-link: #222;
      --color-link-hover: #aaa;
    }

    .demo-7 {
      --color-text: #fff;
      --color-bg: #504f4f;
      --color-link: #666;
      --color-link-hover: #fff;
      --color-top-right: #222;
      --color-bottom-left: #000;
    }

    .completed .demo-7 {
      --color-text: #fff;
      --color-link: #000;
      --color-link-hover: #aaa;
    }

    .demo-8 {
      --color-text: #fff;
      --color-bg: #504f4f;
      --color-link: #666;
      --color-link-hover: #fff;
      --color-top-right: #222;
      --color-bottom-left: #000;
    }

    .completed .demo-8 {
      --color-text: #fff;
      --color-link: #000;
      --color-link-hover: #aaa;
    }

    /* Fade effect */

    .hidden {
      position: absolute;
      overflow: hidden;
      width: 0;
      height: 0;
      pointer-events: none;
    }

    .message {
      position: relative;
      z-index: 100;
      display: none;
      padding: 1em;
      text-align: center;
      color: var(--color-bg);
      background: var(--color-text);
    }

    /* Icons */

    .icon {
      display: block;
      width: 1.5em;
      height: 1.5em;
      margin: 0 auto;
      fill: currentColor;
    }

    .icon--keyboard {
      display: none;
    }

    .icon--debug {
      display: none;
    }

    main {
      position: relative;
      width: 100%;
    }

    .loader {
      background: transparent;
      transition: opacity 600ms;
      border-radius: 50%;
      width: 50%;

      @media (max-width: ${mobileMaxWidthPx}) {
        width: unset;
      }
    }

    .loader canvas {
      border-radius: 50%;
      display: flex;
      margin: auto;
    }

    .loading .loader {
      opacity: 1;
    }

    .completed .loader {
      opacity: 0;
      pointer-events: none;
    }

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      min-height: 100vh;
    }

    .content-outer {
      background: var(--color-bg);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      min-height: 100vh;
      opacity: 0;
      perspective: 300px;
      pointer-events: none;
      transition: opacity 600ms;
    }

    .completed .content-outer {
      opacity: 1;
      pointer-events: auto;
      transition-duration: 0ms;
    }

    .content-inner {
      text-align: center;
      margin: 0 auto;
      max-width: 1000px;
      opacity: 0;
      padding: 0 1em;
      transform: translate3d(0, 0, -200px);
      transition:
        opacity 800ms cubic-bezier(0.19, 1, 0.22, 1),
        transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .completed .content-inner {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .content-bg {
      position: absolute;
      width: 80vmin;
      height: 80vmin;
      top: calc(50% - 40vmin);
      left: calc(50% - 40vmin);
    }

    .content-inner h1 {
      font-size: 3.5vw;
      font-weight: 400;
      letter-spacing: -0.035em;
      line-height: 1.2;
      margin: 0 0 40px;
      text-transform: lowercase;
      position: relative;
    }

    .content-inner p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 40px;
      position: relative;
    }

    .content-inner h1,
    .content-inner p {
      transition: color 0ms 600ms;
    }

    .completed .content-inner h1,
    .completed .content-inner p {
      transition: color 0ms 0ms;
    }

    .content-inner .replay-animation {
      background: var(--color-bg);
      box-shadow: inset 0 0 0 3px var(--color-link);
      color: var(--color-link);
      display: inline-block;
      font-weight: 400;
      padding: 15px 25px;
      transition: all 300ms;
      text-transform: lowercase;
      position: relative;
    }

    .content-inner .replay-animation:hover {
      background: var(--color-link);
      color: var(--color-bg);
    }

    .content--fixed {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      display: grid;
      align-content: space-between;
      width: 100%;
      max-width: none;
      min-height: 0;
      height: 100vh;
      padding: 1.5em;
      pointer-events: none;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto 4em;
      grid-template-areas:
        "header debug"
        \t"... ..."
        \t"github demos";
    }

    .content--fixed a {
      pointer-events: auto;
    }

    /* Header */

    .codrops-header {
      position: relative;
      z-index: 100;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-items: center;
      align-self: start;
      grid-area: header;
      justify-self: start;
    }

    .codrops-header__title {
      font-size: 1em;
      font-weight: 400;
      margin: 0;
      padding: 0.75em 0;
    }

    .github {
      display: block;
      align-self: end;
      grid-area: github;
      justify-self: start;
    }

    .demos {
      position: relative;
      display: block;
      align-self: end;
      text-align: center;
      grid-area: demos;
    }

    .demo {
      margin: 0 0.15em;
      transition: all 300ms;
    }

    .demo:hover,
    .demo:focus {
      opacity: 0.5;
    }

    .demo span {
      white-space: nowrap;
      text-transform: lowercase;
      pointer-events: none;
    }

    .demo span::before {
      content: "#";
    }

    a.demo--current {
      pointer-events: none;
    }

    .debug {
      grid-area: debug;
    }

    .timescale-wrap {
      display: none;
      visibility: hidden;
    }

    /* Top Navigation Style */

    .codrops-links {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 0 1em 0 0;
      text-align: center;
      white-space: nowrap;
    }

    .codrops-icon {
      display: inline-block;
      margin: 0.15em;
      padding: 0.25em;
    }
  }
`;
