import {FC, memo, useState} from "react";
import styled from "styled-components";
import {theme} from "../../config/theme";
import {IButtonProps} from "./types";

const Button: FC<IButtonProps> = memo((props) => {
  const {children} = props;

  const [isButtonActive, setIsButtonActive] = useState(false);

  return (
    <ButtonWrapper
      onMouseEnter={() => setIsButtonActive(true)}
      onMouseLeave={() => setIsButtonActive(false)}
    >
      <ButtonBackgroundDefault isActive={isButtonActive} />
      <ButtonBackgroundHovered isActive={isButtonActive} />
      <ButtonText isActive={isButtonActive}>{children}</ButtonText>
    </ButtonWrapper>
  );
});

const ButtonWrapper = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: ${theme.borderRadius.md};
`;

const ButtonBackground = styled.div<{isActive: boolean}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform-origin: center;
  transition: transform 0.5s ease;
  opacity: 0.3;
  border-radius: 50%;
`;

const ButtonBackgroundDefault = styled(ButtonBackground)`
  background: ${theme.colors.translucent.white(0.4)};
  transform: ${({isActive}) =>
    isActive ? "translate(-50%, -50%) scale(0)" : "translate(-50%, -50%) scale(15)"};
`;

const ButtonBackgroundHovered = styled(ButtonBackground)`
  background: ${theme.colors.gold};
  transform: ${({isActive}) =>
    isActive ? "translate(-50%, -50%) scale(15)" : "translate(-50%, -50%) scale(0)"};
`;

const ButtonText = styled.div<{isActive: boolean}>`
  transition: color 0.5s ease;
  color: ${({isActive}) => (isActive ? theme.colors.white : theme.colors.translucent.white(0.8))};
`;

export default Button;
