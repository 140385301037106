import {css} from "styled-components";

export default css`
  .blur-text--4 {
    font-variation-settings:
      "wght" 400,
      "wdth" 95;
    letter-spacing: -0.05em;
  }
`;
