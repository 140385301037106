import {css} from "styled-components";
import {mobileMaxWidthPx} from "../config/consts";
import {theme} from "../config/theme";

export default css`
  canvas {
    touch-action: none;
  }

  :root {
    --img-ratio: 1.3;
  }

  #catalog-block {
    .grid {
      width: 100%;
      display: grid;
      position: relative;
      grid-row-gap: 3rem;

      @media (min-width: ${mobileMaxWidthPx}) {
        gap: 3vw;
        grid-template-columns: repeat(10, 1fr);
        grid-auto-rows: auto;
      }
    }

    .grid__item-img {
      position: relative;
      overflow: hidden;
      display: grid;
      place-items: center;
      width: 100%;
      height: auto;
      aspect-ratio: var(--img-ratio);
    }

    .grid__item-img-inner {
      width: 100%;
      height: 100%;
      background-position: 50%;
      background-size: cover;
      position: relative;
    }

    .grid__item-caption {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      transform: translateY(-100%);
    }

    .grid__item-caption h3 {
      font-weight: bold;
      font-size: 1.5em;
      margin: 0;
      color: ${theme.colors.white};
    }
  }
`;
