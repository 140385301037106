import {memo, useEffect, useState} from "react";
import styled from "styled-components";
import System from "../../../components/Particles3D/example-6/system";
import Loader from "../../../components/Particles3D/loader";
import {defaultTransition, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {roadmapBlockFramesText} from "./consts";

const RoadmapBlock = memo(() => {
  const [activeFrameIndex, setActiveFrameIndex] = useState(null);

  useEffect(() => {
    new Loader(System, ".loader-6", {desktop: 500, mobile: 350});
  }, []);

  return (
    <RoadmapBlockWrapper className={"demo-6"} id={"roadmap-block"}>
      <TopContentWrapper>
        <TextWrapper>
          <HeaderTextWrapper className="blur-text--4" data-effect-4>
            Roadmap
          </HeaderTextWrapper>
          <DescriptionTextWrapper className="blur-text--4" data-effect-4>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
            sit aspernatur aut odit aut fugit, sed quia consequuntur magni
          </DescriptionTextWrapper>
        </TextWrapper>
        <div className="loader loader-6"></div>
      </TopContentWrapper>
      <BottomContentWrapper>
        {roadmapBlockFramesText.map((frame, index) => (
          <FrameWrapper
            key={frame.title}
            isFrameActive={activeFrameIndex === index}
            onMouseEnter={() => setActiveFrameIndex(index)}
            onMouseLeave={() => setActiveFrameIndex(null)}
          >
            <FrameHeaderTextWrapper
              isFrameActive={activeFrameIndex === index}
              className="blur-text--4"
              data-effect-4
            >
              {frame.title}
            </FrameHeaderTextWrapper>
            <FrameDescriptionTextWrapper
              isFrameActive={activeFrameIndex === index}
              className="blur-text--4"
              data-effect-4
            >
              {frame.description}
            </FrameDescriptionTextWrapper>
          </FrameWrapper>
        ))}
      </BottomContentWrapper>
    </RoadmapBlockWrapper>
  );
});

const RoadmapBlockWrapper = styled.div`
  padding: 20px 100px 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${theme.colors.darkGrey2};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 15px;
  }
`;

const TopContentWrapper = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
    gap: 20px;
  }
`;

const BottomContentWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  justify-content: center;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: unset;
  }
`;

const HeaderTextWrapper = styled.div`
  font-size: 3.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.5em;
  }
`;

const DescriptionTextWrapper = styled.div`
  font-size: 1.7em;
  letter-spacing: 2px;
  line-height: 1.3;
  text-align: justify;
  color: ${theme.colors.translucent.white(0.8)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.3em;
  }
`;

const FrameWrapper = styled.div<{isFrameActive: boolean}>`
  cursor: pointer;
  flex-basis: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
  transition:
    border ${defaultTransition},
    background ${defaultTransition};
  border: ${({isFrameActive}) =>
    isFrameActive
      ? `2px solid ${theme.colors.translucent.white(0.7)}`
      : `2px solid ${theme.colors.translucent.white(0.6)}`};
  border-radius: ${theme.borderRadius.md};
  background: ${({isFrameActive}) =>
    isFrameActive ? theme.colors.translucent.white(0.06) : theme.colors.translucent.white(0.03)};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 10px;
  }
`;

const FrameHeaderTextWrapper = styled.div<{isFrameActive: boolean}>`
  text-align: center;
  font-size: 1.4em;
  transition: color ${defaultTransition};
  color: ${({isFrameActive}) =>
    isFrameActive ? theme.colors.gold : theme.colors.translucent.gold(0.8)};
`;

const FrameDescriptionTextWrapper = styled.div<{isFrameActive: boolean}>`
  font-size: 1.2em;
  transition: color ${defaultTransition};
  color: ${({isFrameActive}) =>
    isFrameActive ? theme.colors.white : theme.colors.translucent.white(0.8)};
`;

export default RoadmapBlock;
