import {FC, memo, useState} from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {defaultTransition, mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {tokenomicsBlockPlatesDesktop, tokenomicsBlockPlatesMobile} from "./consts";

const TokenomicsBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  const platesList = isMobile ? tokenomicsBlockPlatesMobile : tokenomicsBlockPlatesDesktop;

  return (
    <TokenomicsBlockWrapper>
      <HeaderWrapper className="blur-text--4" data-effect-4>
        Tokenomics
      </HeaderWrapper>
      <PlatesWrapper>
        {platesList.map((row, index) => (
          <PlatesRowWrapper key={index}>
            {row.map((plate) => (
              <PlateComponent key={plate.leftText} plate={plate} />
            ))}
          </PlatesRowWrapper>
        ))}
      </PlatesWrapper>
    </TokenomicsBlockWrapper>
  );
});

const PlateComponent: FC<{plate: any}> = memo((props) => {
  const {plate} = props;

  const [isPlateActive, setIsPlateActive] = useState(false);

  return (
    <PlateWrapper
      key={plate.leftText}
      onMouseEnter={() => setIsPlateActive(true)}
      onMouseLeave={() => setIsPlateActive(false)}
    >
      <PlateColorWrapper />
      <PlateContentWrapper isPlateActive={isPlateActive}>
        <PlateBackgroundWrapper isPlateActive={isPlateActive} />
        <PlateLeftText isPlateActive={isPlateActive} className="blur-text--4" data-effect-4>
          {plate.leftText}
        </PlateLeftText>
        <PlateRightText isPlateActive={isPlateActive} className="blur-text--4" data-effect-4>
          {plate.rightText}
        </PlateRightText>
      </PlateContentWrapper>
    </PlateWrapper>
  );
});

const TokenomicsBlockWrapper = styled.div`
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${theme.colors.darkGrey1};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 15px;
  }
`;

const HeaderWrapper = styled.div`
  font-size: 3.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.5em;
  }
`;

const PlatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PlatesRowWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const PlateWrapper = styled.div`
  cursor: pointer;
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: ${theme.borderRadius.md};
  transition: border ${defaultTransition};
`;

const PlateColorWrapper = styled.div`
  width: 5px;
  background: linear-gradient(
    to right,
    ${theme.colors.translucent.white(0.5)},
    ${theme.colors.gold}
  );
`;

const PlateContentWrapper = styled.div<{isPlateActive: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 100%;
  position: relative;
  font-size: 1.25em;
  background: ${theme.colors.translucent.white(0.08)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.1em;
  }
`;

const PlateBackgroundWrapper = styled.div<{isPlateActive: boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform ${defaultTransition};
  background: ${theme.colors.translucent.gold(0.2)};
  transform: ${({isPlateActive}) =>
    isPlateActive ? `translateX(-15px)` : `translateX(calc(-100% - 15px))`};
`;

const PlateLeftText = styled.div<{isPlateActive: boolean}>`
  transition: color ${defaultTransition};
  color: ${({isPlateActive}) =>
    isPlateActive ? theme.colors.white : theme.colors.translucent.white(0.8)};
`;

const PlateRightText = styled.div<{isPlateActive: boolean}>`
  transition: color ${defaultTransition};
  color: ${({isPlateActive}) =>
    isPlateActive ? theme.colors.translucent.white(0.8) : theme.colors.translucent.white(0.6)};
`;

export default TokenomicsBlock;
