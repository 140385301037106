import {memo} from "react";
import styled from "styled-components";
import {mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";

const InfoBlock = memo(() => {
  return (
    <InfoBlockWrapper>
      <HeaderWrapper className="blur-text--4" data-effect-4>
        About Us
      </HeaderWrapper>
      <ContentWrapper>
        <VideoWrapper>
          <Video
            src={"/images/infoBlockVideo.mp4"}
            autoPlay
            muted
            loop
            controls={false}
            playsInline
          />
        </VideoWrapper>
        <TextWrapper>
          <HeaderTextWrapper className="blur-text--4" data-effect-4>
            Lorem ipsum{" "}
            <HeaderTextHighlightedElementWrapper>
              dolor sit amet,{" "}
            </HeaderTextHighlightedElementWrapper>
            consectetur adipiscing
          </HeaderTextWrapper>
          <DescriptionTextWrapper className="blur-text--4" data-effect-4>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet enim feugiat,
              semper{" "}
              <DescriptionTextHighlightedElementWrapper>
                dolor sit amet, fringilla sem
              </DescriptionTextHighlightedElementWrapper>
              . Quisque ac risus cursus, dictum metus sit amet, bibendum tellus.
            </div>
            <div>
              Aliquam sit amet augue ut mi vulputate pretium. Nunc tortor nulla, pharetra vel
              commodo quis, consequat sed urna. Praesent vitae justo eu risus dictum molestie nec ac
              orci. Etiam ultricies enim et lectus blandit finibus. Phasellus lorem odio, convallis
              id massa vitae,{" "}
              <DescriptionTextHighlightedElementWrapper>
                mattis congue quam.
              </DescriptionTextHighlightedElementWrapper>
            </div>
          </DescriptionTextWrapper>
        </TextWrapper>
      </ContentWrapper>
    </InfoBlockWrapper>
  );
});

const InfoBlockWrapper = styled.div`
  padding: 50px 100px;
  background: ${theme.colors.darkGrey1};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 15px;
  }
`;

const HeaderWrapper = styled.div`
  font-size: 3.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.5em;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }
`;

const VideoWrapper = styled.div`
  flex-basis: 50%;
`;

const Video = styled.video`
  position: relative;
  grid-area: background;
  object-fit: cover;
  width: 100%;
  height: 500px;
  border-radius: ${theme.borderRadius.md};
  border: 3px solid ${theme.colors.translucent.gold(0.5)};

  @media (max-width: ${mobileMaxWidthPx}) {
    height: 350px;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: justify;
`;

const HeaderTextWrapper = styled.div`
  font-size: 3em;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2em;
  }
`;

const HeaderTextHighlightedElementWrapper = styled.span`
  color: ${theme.colors.gold};
`;

const DescriptionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 1.35em;
  color: ${theme.colors.translucent.white(0.8)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
  }
`;

const DescriptionTextHighlightedElementWrapper = styled.span`
  color: ${theme.colors.white};
`;

export default InfoBlock;
