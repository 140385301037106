import {memo} from "react";
import styled from "styled-components";
import {addEffect} from "@react-three/fiber";
import Lenis from "@studio-freight/lenis";
import EmergingImage from "../../../components/EmergingImage";
import {defaultTransition, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {cardsList, type} from "./consts";
import Scene from "./Scene";

const lenis = new Lenis();
addEffect((t) => lenis.raf(t));

const CatalogBlock = memo(() => {
  return (
    <CatalogBlockWrapper id={"catalog-block"}>
      <HeaderWrapper className="blur-text--4" data-effect-4>
        Catalog
      </HeaderWrapper>
      <Scene />
      <div className="grid">
        {cardsList.map((card, index) => (
          <GridItemWrapper
            key={card.name}
            className="grid__item"
            row={card.row}
            flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
          >
            <figcaption className="grid__item-caption blur-text--4" data-effect-4>
              <h3>{card.name}</h3>
            </figcaption>
            <div className="grid__item-img">
              <EmergingImage type={type} url={card.path} className="grid__item-img-inner" />
            </div>
            <TextWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Price:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.price}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Profit:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.profit}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Algorithm:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.algorithm}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Tokens:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.tokens}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Hashrate:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.hashrate}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Power:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.power}
                </TextRightSideWrapper>
              </TextElementWrapper>
              <TextElementWrapper>
                <TextLeftSideWrapper className="blur-text--4" data-effect-4>
                  Efficiency:
                </TextLeftSideWrapper>
                <TextRightSideWrapper className="blur-text--4" data-effect-4>
                  {card.efficiency}
                </TextRightSideWrapper>
              </TextElementWrapper>
            </TextWrapper>
          </GridItemWrapper>
        ))}
      </div>
    </CatalogBlockWrapper>
  );
});

const CatalogBlockWrapper = styled.div`
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: ${theme.colors.darkGrey2};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 15px;
  }
`;

const HeaderWrapper = styled.div`
  font-size: 3.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.5em;
  }
`;

const GridItemWrapper = styled.figure<{
  row: number;
  flexDirection: string;
}>`
  display: flex;
  gap: 20px;
  position: relative;
  margin: 0;
  flex-direction: ${({flexDirection}) => flexDirection};

  :not(:last-child) {
    margin-bottom: 30px;
  }

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }

  @media (min-width: ${mobileMaxWidthPx}) {
    grid-column: 1 / span 10;
    grid-row: ${({row}) => row};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  text-align: center;
  font-size: 1.65em;
  height: max-content;
  margin: auto;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: 100%;
    font-size: 1.3em;
  }
`;

const TextLeftSideWrapper = styled.div`
  color: ${theme.colors.translucent.gold(0.8)};
  transition: color ${defaultTransition};
`;

const TextRightSideWrapper = styled.div`
  color: ${theme.colors.translucent.white(0.8)};
  transition: color ${defaultTransition};
`;

const TextElementWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: default;
  gap: 10px;
  padding: 10px;
  border-radius: ${theme.borderRadius.sm};
  transition: background ${defaultTransition};

  :hover {
    background: ${theme.colors.translucent.white(0.08)};

    ${TextLeftSideWrapper} {
      color: ${theme.colors.gold};
    }
    ${TextRightSideWrapper} {
      color: ${theme.colors.white};
    }
  }
`;

export default CatalogBlock;
