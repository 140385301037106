export const defaultTransitionDuration = 250;
export const defaultTransition = `${defaultTransitionDuration}ms ease-in-out`;

export const mobileMaxWidth = 750;
export const mobileMaxWidthPx = `${mobileMaxWidth}px`;

export const desktopDefaultWidth = 1900;
export const desktopDefaultWidthPx = `${desktopDefaultWidth}px`;

export const blocksHeight = {};
