export const type = 3;

export const cardsList = [
  {
    name: "Antminer KS5 Pro",
    path: "/images/catalog/antminer-ks5-pro.png",
    row: 1,
    price: "10.000 $HASH",
    profit: "$87 / day",
    algorithm: "KHeavyHash",
    tokens: "KAS-Kaspa",
    hashrate: "21 ± 10% TH",
    power: "3150 W",
    efficiency: "150 J/T",
  },
  {
    name: "Antminer S21 Hydro",
    path: "/images/catalog/antminer-s21-hydro.png",
    row: 2,
    price: "2.100 $HASH",
    profit: "$18 / day",
    algorithm: "SHA-256",
    tokens: "BTC/BCH",
    hashrate: "335 ± 3% TH/s",
    power: "5360 ±5% W",
    efficiency: "16 ± 5% J/T",
  },
  {
    name: "Whatsminer M53S",
    path: "/images/catalog/whatsminer-m53s.png",
    row: 3,
    price: "1.600 $HASH",
    profit: "$14 / day",
    algorithm: "SHA-256",
    tokens: "BTC/BCH",
    hashrate: "320 ± 5% TH",
    power: "6864 ± 10% W",
    efficiency: "22 ± 5% J/TH",
  },
  {
    name: "Antminer E9 Pro",
    path: "/images/catalog/antminer-e9-pro.png",
    row: 4,
    price: "700 $HASH",
    profit: "$6 / day",
    algorithm: "ETChash",
    tokens: "ETC/ETHW/ETHF",
    hashrate: "3680 ± 10% MH",
    power: "2200 ± 10% W",
    efficiency: "0.6 ± 10% J/TH",
  },
  {
    name: "ElphaPex DG1 Plus",
    path: "/images/catalog/ElphaPex-DG1-plus.png",
    row: 5,
    price: "350 $HASH",
    profit: "$3 / day",
    algorithm: "Scrypt",
    tokens: "LTC/DOGE",
    hashrate: "11 GH",
    power: "3420 ± 10% W",
    efficiency: "0.31 ± 10% MH",
  },
];
