import {memo, useEffect} from "react";
import styled from "styled-components";
import Link from "../../../components/Link";
import System from "../../../components/Particles3D/example-1/system";
import Loader from "../../../components/Particles3D/loader";
import {mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";

const FooterBlock = memo(() => {
  useEffect(() => {
    new Loader(System, ".loader-1", {desktop: 250, mobile: 200});
  }, []);

  return (
    <FooterBlockWrapper id={"footer-block"}>
      <Particles3dWrapper className="loader loader-1"></Particles3dWrapper>
      <LinksWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Title 1</LinksColumnTitleWrapper>
          <Link href="">Link 1</Link>
          <Link href="">Link 2</Link>
          <Link href="">Link 3</Link>
        </LinksColumnWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Title 2</LinksColumnTitleWrapper>
          <Link href="">Link 1</Link>
          <Link href="">Link 2</Link>
        </LinksColumnWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Title 3</LinksColumnTitleWrapper>
          <Link href="">Link 1</Link>
          <Link href="">Link 2</Link>
          <Link href="">Link 3</Link>
          <Link href="">Link 4</Link>
        </LinksColumnWrapper>
      </LinksWrapper>
    </FooterBlockWrapper>
  );
});

const FooterBlockWrapper = styled.div`
  padding: 50px 100px;
  display: flex;
  gap: 10px;
  align-items: center;
  background: ${theme.colors.darkGrey2};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 15px;
    flex-direction: column;
  }
`;

const Particles3dWrapper = styled.div`
  @media (min-width: ${mobileMaxWidthPx}) {
    flex-basis: 30%;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  color: ${theme.colors.translucent.white(0.9)};

  @media (min-width: ${mobileMaxWidthPx}) {
    flex-basis: 70%;
  }
`;

const LinksColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.2em;

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1em;
  }
`;

const LinksColumnTitleWrapper = styled.div`
  margin-left: 5px;
  color: ${theme.colors.translucent.gold(0.8)};
  font-size: 1.3em;

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
  }
`;

export default FooterBlock;
