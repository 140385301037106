import {memo} from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import Button from "../../../components/Button";
import Link from "../../../components/Link";
import {mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";

const HeaderBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  return (
    <HeaderWrapper isMobile={isMobile}>
      <HeaderLeftWrapper>
        <Link href={""}>Link 1</Link>
        <Link href={""}>Link 2</Link>
        <Link href={""}>Link 3</Link>
        <Link href={""}>Link 4</Link>
      </HeaderLeftWrapper>
      <HeaderRightWrapper>
        <Button>Sign In</Button>
      </HeaderRightWrapper>
    </HeaderWrapper>
  );
});

const HeaderWrapper = styled.div<{isMobile: boolean}>`
  padding: 10px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3em;
  gap: 20px;
  color: ${theme.colors.translucent.white(0.8)};
  flex-direction: ${({isMobile}) => (isMobile ? "column-reverse" : "row")};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 10px 15px;
  }
`;

const HeaderLeftWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const HeaderRightWrapper = styled.div``;

export default HeaderBlock;
