import {css} from "styled-components";
import {theme} from "../config/theme";

export default css`
  .toast-common {
    background: ${theme.colors.grey};
    border: 1px solid ${theme.colors.translucent.white(0.1)};
    box-shadow:
      2px 2px 5px ${theme.colors.translucent.white(0.1)},
      -2px -2px 5px ${theme.colors.translucent.white(0.1)};
    font-size: ${theme.fonts.sizes.md};
  }

  .toast-common.Toastify__toast--info .Toastify__toast-icon svg {
    fill: ${theme.colors.white};
  }
`;
